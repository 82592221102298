<template>
  <div class="order-completed-page">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="order-completed">
          <div class="columns content-order-completed">
            <div class="column is-12 right-column">
              <h3 class="title is-2 pb-4 txt-c-mobile has-text-centered">Congratulations, your pickup has been reserved.</h3>

              <p class="has-text-centered">
                Please check your email address <a class="has-text-weight-bold" :href="'mailto:' + user.email">{{user.email}}</a> for further informations.<br>
                In order to ensure a correct pickup, please follow those simple steps:
              </p>

              <p class="has-text-centered mt-5 mb-5">
                <img src="../assets/images/pickup-icon-1.png">
              </p>

              <p class="has-text-centered">
                Prepare your package, print and insert the order detail list, and carefully seal it.
              </p>

              <p class="has-text-centered mt-5 mb-5">
                <img src="../assets/images/pickup-icon-2.png">
              </p>

              <p class="has-text-centered">
                Download and print the shipping label, and attach it to your package.
              </p>

              <p class="has-text-centered mt-5 mb-5">
                <img src="../assets/images/pickup-icon-3.png">
              </p>

              <p class="has-text-centered">
                Wait for the courier to pickup the package
              </p>

              <div class="columns is-centered mt-5">
                <div class="column is-5 mt-5 has-text-centered">
                  <button class="button is-primary is-medium mb-5 is-fullwidth" @click="downloadPdf()">
                    Download PDF
                  </button>
                  <button class="button is-primary is-medium is-outlined  is-fullwidth" @click="goBack()">
                    Back to your orders
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeroImage from '@/components/HeroImage.vue'
export default {
  name: 'OrderCompleted',
  components: {
    HeroImage
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      isClinicLoggedIn: 'user/isClinicLoggedIn',
      isLabLoggedIn: 'user/isLabLoggedIn',
      laboratory: 'user/laboratory',
      clinic: 'user/clinic'
    })
  },
  methods: {
    downloadPdf () {},
    goBack () {
      if (this.isClinicLoggedIn) {
        this.$router.push({ name: 'ClinicOrders' })
      } else {
        this.$router.push({ name: 'LaboratoryLabOrders' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.order-completed-page{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    padding-bottom: 100px;
    &.over-hero{
      position: relative;
      margin-top: -360px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .order-completed{
      width: 80%;
      margin: 0 auto;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-order-completed{
        padding: 0px;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .right-column{
          padding: 80px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .order-completed{
    section{
      .order-completed{
        .content-order-completed{
          .right-column{
            padding: 50px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .order-completed{
    section{
      &.over-hero{
        margin-bottom: 0px;
      }
      .order-completed{
        .content-order-completed{
          .title{
            &.is-2{
              font-size: 1.5rem!important;
            }
          }
          .right-column{
            padding: 30px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .order-completed{
    section{
      .order-completed{
        width: 90%;
      }
    }
  }
}
</style>
